import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SuggestionsList from "./SuggestionsList";
import { autoCompleter } from "../utils/helpers";

class FixedSearchInput extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      showSuggestion: false,
      list: [],
    };
    this.validate = this.validate.bind(this);
    this.changes = this.changes.bind(this);
  }
  navigate(path) {
    if (path) this.props.history.push(`/words/${path.toLocaleLowerCase()}`);
    else window.location.reload();
  }
  toggleSuggestions = (val = undefined) => {
    this.setState({
      showSuggestion:
        typeof val === "boolean" ? val : !this.state.showSuggestion,
    });
  };
  changes() {
    let value = this.input.current.value.trim();
    autoCompleter(value, (res) => {

    if(res.data.length > 0)  this.setState({
        list: res?.data || [] 
      })
    });
    this.toggleSuggestions(Boolean(value));
    let list = Array.from(this.form.current.classList);
    if (value && list.includes("border-red-500")) {
      this.form.current.classList.remove("border-red-500");
      this.form.current.classList.add("border-gray-500");
    }
  }
  validate() {
    let value = this.input.current.value.trim();

    if (!value) {
      this.form.current.classList.remove("border-gray-500");
      this.form.current.classList.add("border-red-500");
    } else {
      this.navigate(value);
    }
  }
  render() {
    return (
      <div className="fixed-input">
        <form
          ref={this.form}
          onSubmit={this.validate}
          className="flex justify-center border-2 border-solid border-gray-500 bg-gray-200 rounded-xl overflow-hidden max-w-xs"
        >
          <input
            onChange={this.changes}
            ref={this.input}
            placeholder="Type a word."
            required
            className="block rounded-md focus:outline-none focus:ring-0 focus:border-blue-500 flex-grow p-2"
          />
          <button onClick={this.validate} type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 my-auto m-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </form>
        {this.state.showSuggestion && this.state.list.length > 0 ? (
          <SuggestionsList
            toggleSuggestions={this.toggleSuggestions}
            list={this.state.list ?? []}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(FixedSearchInput);
