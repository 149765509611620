import React, { Component } from "react";
import { addArchorTag } from "../utils/helpers";

export default class TextBlock extends Component {
  render() {
    return (
      <div className="text-block">
        <p className="text-left">
          {this.props.children}{" "}
          <span key={this.props.text}>
            {addArchorTag(this.props.text.trim())}
          </span>
        </p>
      </div>
    );
  }
}


