import { urlencoded } from "body-parser";
import React from "react";
import axios from 'axios'

/**
 *
 * @param {string} word
 * @param {Function} callback
 */
export const autoCompleter = (word, callback) => {
  if (!word) return;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer SECRET_TOKEN");

  let options = {
    method: "GET",
    headers: myHeaders,
  };
  fetch(
    `https://json.byteestudio.com/api/v1/autocomplete/${encodeURI(
      word.trim()
    )}?limit=10`,
    options
  )
    .then((response) => response.json())
    .then(callback)
    .catch(console.error);
};
/**
 *
 * @param {string} word
 */
export const getWordContent = async (word) => {
  try {
    let url = "https://data.mongodb-api.com/app/data-dcpvk/endpoint/data/v1/action/findOne";
    let access_token = await _accessToken()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", access_token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Request-Headers", "*");

    var raw = JSON.stringify({
      "collection": "contents",
      "database": "wordsCluster",
      "dataSource": "wordsCluster",
      "filter": {
        "key": "dish"
      }
    });

    const config = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
        'Authorization': 'Bearer ' + access_token,
      },
      data: raw
    };

    const response = await axios(config)


    const data = await response.data;


    ////

    if (!data) {
      // eslint-disable-next-line no-throw-literal
      throw "No Data";
    }

    return data.document.data;
  } catch (error) {
    console.error(error);

    return {
      error: error.message,
    };
  }
};

async function _accessToken() {
  try {
    let url = 'https://realm.mongodb.com/api/client/v2.0/app/data-dcpvk/auth/providers/local-userpass/login';
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "username": "ndondo330@gmail.com",
      "password": "6LPUDRlTP208wOp6"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const response = await fetch(url, requestOptions)

    const data = await response.json()

    return data.access_token;
  } catch (error) {
    console.error(error);
    return "TOKEN"
  }
}

/**
 *
 * @param {string} words
 * @returns
 */
export function addArchorTag(words) {
  let linkables = window?.linkable || [];
  /**
   *
   * @param {React.ReactNode[]} children
   * @returns
   */
  const outputEl = (children) =>
    React.createElement(
      "span",
      { className: "div-with-anchor-tags" },
      ...children
    );

  let output = [];
  let number = 1000;

  words.split(" ").map((word) => {
    number++;
    let toInsertSpace = output.length > 0;
    toInsertSpace && output.push(React.createElement("span", null, " "));

    if (
      linkables.includes(
        word.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
      )
    )
      output.push(
        React.createElement(
          "a",
          {
            className: "underline text-blue-500",
            href: `/words/${word.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ""
            )}`,
          },
          word
        )
      );
    else output.push(React.createElement("span", null, word));
  });

  return outputEl(output);
  //  <a href={match} className="underline text-blue-500" >{match}</a>
}











