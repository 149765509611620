import React, { Component } from "react";
import LeftArrowIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { Link } from "react-router-dom";
export default class Header extends Component {
  render() {
    return (
      <header>
        <div className="h-14 px-4 pt-4 flex flex-row group max-w-max max-h-9 my-auto">
          <Link to="/">
            <LeftArrowIcon className="min-h-6 my-auto group-hover:text-blue-600 group-focus:text-blue-600" />
          </Link>
          <span className="text-left  max-w-max min-h-6 mb-2 my-auto group-hover:text-blue-600 group-focus:text-blue-600">
            Search
          </span>
        </div>
      </header>
    );
  }
}
