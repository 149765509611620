import React, { Component } from 'react'
import FixedSearchInput from '../components/FixedSearchInput'
import History from '../components/History'

export default class Search extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
   
        return (
            <div className='px-4 pb-4 pt-32'>
                <h5 className="head-font-100 py-3 text-4xl">
                    Dictionary
                </h5>
                <div className="pt-2 pb-4">
                   <FixedSearchInput />
                </div>
                <History/>
            </div>
        )
    }
}
