import React, { Component } from "react";

export default class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: this.getLocal().map(i => i.split("__")[0]),
    };
    this.getLocal = this.getLocal.bind(this);
    this.setLocal = this.setLocal.bind(this);
  }
  /**
   *
   * @param {string} val
   */
  setLocal(val) {
    let listMaxIndex = 0;
    ///data + meta
    let raw = this.getLocal();
    //sanitize
    let list = raw.map((i) => {
      let spltData = i.split("__");
      if (Number(spltData[1]) > listMaxIndex) {
        listMaxIndex = Number(spltData[1]);
      }

      return { index: spltData[1], word: spltData[0] };
    });


    /// filter old data
    let filteredList = list.filter((current, _, _$) => {
      let earliest = listMaxIndex - 10; // or 9


      return listMaxIndex > 10 ? current.index > earliest : true;
    });
    /// data
    let data = [...filteredList.filter(i => i.word !== val.toLowerCase()).map(i => `${i.word}__${i.index}`), `${val.toLowerCase()}__${listMaxIndex + 1}`].filter(onlyUnique);
    localStorage.setItem("history", JSON.stringify(data));

    this.setState({
      history: this.getLocal().map(i => i.split("__")[0]),
    });
  }
  /**
   *
   * @returns {Array}
   */
  getLocal() {
    return JSON.parse(localStorage.getItem("history") || "[]");
  }

  render() {
    return this.state.history.length > 0 ? (
      <div className="recent">
        <h5 className="head-font-100 py-1 text-3xl">Recent</h5>
        <ul className="list pb-6 pt-2 text-left text-xl text-gray-600 capitalize">
          {this.state.history.map((i) => (
            <a key={i} className="" href={`/words/${i.toLowerCase()}`}>
              <li key={btoa(i)} className="list-item pb-1">
                {i}
              </li>
            </a>
          ))}
        </ul>
      </div>
    ) : null;
  }
}

/**
 *
 * @param {string} value
 * @param {number} index
 * @param {Array<string>} array
 * @returns {boolean}
 */
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
