//import React, { Component } from "react";
import PrefixNumber from "./PrefixNumber";
import React from "react";
import PropTypes from "prop-types";
import SecondaryHeader from "./SecondaryHeader";
import SuffixNumber from "./SuffixNumber";
import TextBlock from "./TextBlock";
import WordsList from "./WordsList";
import { addArchorTag } from "../utils/helpers";

export default class Definition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      val: 0,
    };
  }

  render() {

    /// increment with every new definition

    let number = 0;
    return (
      <div className="defination mt-8">
        <div className="defination-head flex">
          <SecondaryHeader title={this.props.itemData.headtext} />
          {(this.props.itemData.hasOwnProperty("definitions") ||
            typeof this.props.itemData.subtext !== "string") && (
            <SuffixNumber
              number={
                typeof this.props.itemData.subtext !== "string"
                  ? this.props.itemData.subtext.length
                  : this.props.itemData.definitions.length
              }
            />
          )}
        </div>
        {typeof this.props.itemData.subtext !== "string"
          ? (() => {
              let ogList = this.props.itemData.subtext;

              let listCopy = ogList.splice(
                ogList.length / 2,
                ogList.length - 1
              );
              return (
                <div className="suggested-words grid grid-flow-col">
                  <WordsList list={listCopy} />
                  <WordsList list={ogList} />
                </div>
              );
            })()
          : this.props.itemData.subtext && (
              <p
                className="text-gray-400 mb-2"
              >
                <i> {addArchorTag(this.props.itemData.subtext)}</i>
              </p>
            )}
        {this.props.itemData.hasOwnProperty("definitions") &&
          this.props.itemData["definitions"].map((i) => {
            number = number + 1;
            return (
              <div key={JSON.stringify(i)} className="defination-text flex">
                <TextBlock text={i.definition}>
                  <PrefixNumber number={number} />
                </TextBlock>
              </div>
            );
          })}
      </div>
    );
  }
}

Definition.propTypes = {
  itemData: PropTypes.object,
};



