import React, { Component } from 'react'
import SoundIcon from "@mui/icons-material/VolumeUpSharp";
export default class FixedHeader extends Component {
    componentDidMount(){
        document.querySelector("nav.fixed-header").style.display = "none";
    }
    render() {
        window.addEventListener("scroll", function () {
            let element = document.querySelector("nav.fixed-header");
            if (element&&(document.body.scrollTop > 150 ||document.documentElement.scrollTop > 150)) {
                  element.style.display = "flex";
            } else if(element) {
                  element.style.display = "none";
            }
          });
        return (
            <nav className='fixed-header flex justify-between shadow-lg sticky w-full left-0 top-0 p-5 z-50'>
                <h1 className="font-bold text-2xl head-font-100 sticky top-0 max-w-max capitalize">{this.props.title}</h1>
                <SoundIcon className="text-blue-600 mt-[8px]" fontSize='large'  />
            </nav>
        )
    }
}
