import React, { Component } from "react";
import SoundIcon from "@mui/icons-material/VolumeUpSharp";
export default class TheHero extends Component {
  render() {
    return (
      <div className="mx-3 mb-8 mt-12 sticky">
        <h1 className="font-bold text-5xl head-font-100 top-0 capitalize pb-3">
          {this.props.title}
        </h1>
        <span className="text-blue-600">{this.props.children}</span>{" "}
        <span>
          <SoundIcon className="text-blue-600 mt-0 ml-2" />
        </span>
      </div>
    );
  }
}
