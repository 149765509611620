import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Search from "./pages/Search";
import Result from "./pages/Result";
import Error from "./pages/Error";
import MobileWrapper from "./components/MobileWrapper";
import Header from "./components/Header";
import FixedHeader from "./components/FixedHeader";


export default class App extends Component {
  constructor(props) {
    super(props);
    this.setInput = this.setInput.bind(this);
    this.fetchInput = this.fetchInput.bind(this);
    this.state = {
      input: "",
      result: {},
      isRunning: false,
    };
    /// add placer holder data,if empty
    let localStrg = localStorage.getItem("history");

    if (!localStrg) {
      localStorage.setItem(
        "history",
        '["shame__10","spell__9","charisma__8","personality__7","sarcastic__6","incisive__5","castle__4","carry__3","transport__2","body__1"]'
      );
    }
  }

  fetchInput(input) {
    this.setState({ input, isRunning: true });
  }
  setInput(input) {
    this.setState({ input });
  }
  render() {
    return (
      <MobileWrapper>
        <Router>
          <Switch>
            <Route exact path="/">
              <Search fetchInput={this.fetchInput} />
            </Route>
            <Route exact path="/words/:search">
              <Header />
              <FixedHeader title={this.state.input} />
              <Result
                isRunning={this.state.isRunning}
                setInput={this.setInput}
                fetchInput={this.fetchInput}
                input={this.state.input}
                result={this.state.result}
              />
            </Route>
            <Route path="*">
              <Error />
            </Route>
          </Switch>
        </Router>
      </MobileWrapper>
    );
  }
}
