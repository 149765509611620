import React, { Component } from 'react'

export default class Error extends Component {
    render() {
        return (
            <div className='p-4 max-w-max m-auto mt-20'>
                <h1 className='text-bold text-xl text-center' >Error Occurred</h1>
                <button className='py-2 px-10 my-10 border-2 border-gray-600' onClick={() => window.location.assign("/")}  >REFRESH</button>
            </div>
        )
    }
}
