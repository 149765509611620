import { ClickAwayListener } from "@mui/material";
import React from "react";

function SuggestionsList(props) {
  const { list } = props;
  return (
    <ClickAwayListener onClickAway={() => props.toggleSuggestions()}>
      <div className="list-output bg- max-w-xs bg-gray-200 overflow-scroll max-h-80">
        <ul className="bg-gray-200 overflow-scroll">
          {list.map((i) => (
            <a key={i} className="" href={`/words/${i.toLowerCase()}`}>
              <li className="list-item">{i}</li>
            </a>
          ))}
        </ul>
      </div>
    </ClickAwayListener>
  );
}

export default SuggestionsList;
