import React, { Component } from "react";
import { withRouter } from "react-router";
import Definition from "../components/Definition";
import TheHero from "../components/TheHero";
import WordsList from "../components/WordsList";
import { getWordContent } from "../utils/helpers";
import Footer from "../components/Footer";

class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pronunciation: {},
      audio: "",
      /// null | object
      data: null,
    };
    this.getLocal = this.getLocal.bind(this);
    this.setLocal = this.setLocal.bind(this);
  }

  /**
   *
   * @param {string} val
   */
  setLocal(val) {
    let listMaxIndex = 0;
    ///data + meta
    let raw = this.getLocal();
    //sanitize
    let list = raw.map((i) => {
      let spltData = i.split("__");
      if (Number(spltData[1]) > listMaxIndex) {
        listMaxIndex = Number(spltData[1]);
      }

      return { index: spltData[1], word: spltData[0] };
    });

    /// filter old data
    let filteredList = list.filter((current, _, _$) => {
      let earliest = listMaxIndex - 10; // or 9

      return listMaxIndex > 10 ? current.index > earliest : true;
    });
    /// data
    let data = [
      `${val.toLowerCase()}__${listMaxIndex + 1}`,
      ...filteredList
        .filter((i) => i.word !== val.toLowerCase())
        .map((i) => `${i.word}__${i.index}`),
    ].filter(onlyUnique);

    localStorage.setItem("history", JSON.stringify(data));

    this.setState({
      history: this.getLocal().map((i) => i.split("__")[0]),
    });
  }
  /**
   *
   * @returns {Array<string>}
   */
  getLocal() {
    return JSON.parse(localStorage.getItem("history") || "[]");
  }

  // componentDidUpdate() {
  //   let value = this.props.match.params.search;
  //   this.props.setInput(value);
  //   this.props.fetchInput(value)
  // }
  async componentDidMount() {
    let value = this.props.match.params.search.trim();
 

    this.props.setInput(value);

    if (!this.props.isRunning) {
      this.props.fetchInput(value);
    }

    let data = await getWordContent(value);

    /// bool flag to indicate if the response was successful
    let hasNoData = data.hasOwnProperty("status");


    if (!hasNoData) {
      ///
      this.setLocal(value);
      /// pronunciation data
      let pronunciation = data.pronunciation || data.pronunciation_2;
      let audio = data.audio && data.audio.length > 0 ? data.audio[0] : "";
      window.linkable = data.linkable;
      /// then remove from the main object
      delete data.pronunciation;
      delete data.pronunciation_2;
      delete data.audio;
      delete data.category;
      delete data.linkable;
      /// store data
      this.setState({
        pronunciation,
        data,
        audio,
        word: value,
      });
    } else {
      window.location.assign("/error/404");
    }
  }
  render() {
    if (!this.state.data) {
      return (
        <div className="m-auto max-w-max h-52 py-24 font-semibold">
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <>
        <main className="p-4">
          <TheHero audio={this.state.audio} title={this.props.input}>
            {this.state.pronunciation ? this.state.pronunciation?.subtext : ""}
          </TheHero>

          {Object.values(this.state.data).map((item) => {
            if (typeof item.length === "number") return null;
            return <Definition key={item.headtext} itemData={item} />;
          })}
        </main>
        {/* <Footer/> */}
      </>
    );
  }
}

export default withRouter(Result);

/**
 *
 * @param {string} value
 * @param {number} index
 * @param {Array<string>} array
 * @returns {boolean}
 */
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
