import React, { Component } from "react";
import SecondaryHeader from "./SecondaryHeader";
import SuffixNumber from "./SuffixNumber";

export default class WordsList extends Component {
  render() {
    let count = 0;
    return (
      <div className="my-3 max-w-max">
        {/* <div className="flex">
                   <SecondaryHeader title={"pope"}/>
                   <SuffixNumber number={8}/>
                </div> */}
        <ul className="list">
          {this.props.list.map((i) => {
            count++;
            return (
              <li key={i + "_" + count} className="list-item text-blue-500 ">
                <a href={`/words/${i}`}>{i}</a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
